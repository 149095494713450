import React from "react";

const Footer = () => (
  <footer>
    <p>
      Developed by{" "}
      <a
        href="https://github.com/write-arsalan-nawaz"
        target="_blank"
        rel="noopener noreferrer"
      >
        Arsalan Nawaz
      </a>
    </p>
  </footer>
);

export default Footer;
