import React, { useState } from "react";

const PizzaAreaCalculator = () => {
  const [option1Pizzas, setOption1Pizzas] = useState([]);
  const [option2Pizzas, setOption2Pizzas] = useState([]);
  const [result, setResult] = useState(null);
  const [option1Result, setOption1Result] = useState(null);
  const [option2Result, setOption2Result] = useState(null);
  const [conclusionResult, setConclusionResult] = useState("");

  const addPizzaOption1 = () => {
    setOption1Pizzas([...option1Pizzas, { size: "", quantity: 1, price: "" }]);
  };

  const addPizzaOption2 = () => {
    setOption2Pizzas([...option2Pizzas, { size: "", quantity: 1, price: "" }]);
  };

  const handleInputChange = (e, index, option) => {
    const { name, value } = e.target;
    const pizzas = option === 1 ? [...option1Pizzas] : [...option2Pizzas];
    pizzas[index][name] = value;
    option === 1 ? setOption1Pizzas(pizzas) : setOption2Pizzas(pizzas);
  };

  const removePizza = (index, option) => {
    if (option === 1) {
      const updatedPizzas = option1Pizzas.filter((_, i) => i !== index);
      setOption1Pizzas(updatedPizzas);
    } else {
      const updatedPizzas = option2Pizzas.filter((_, i) => i !== index);
      setOption2Pizzas(updatedPizzas);
    }
  };

  const calculateBestValue = () => {
    const calculateArea = (size) => Math.PI * Math.pow(size / 2, 2);
  
    const totalAreaOption1 = option1Pizzas.reduce(
      (acc, pizza) => acc + calculateArea(pizza.size) * pizza.quantity,
      0
    );
    const totalPriceOption1 = option1Pizzas.reduce(
      (acc, pizza) => acc + pizza.price * pizza.quantity,
      0
    );
  
    const totalAreaOption2 = option2Pizzas.reduce(
      (acc, pizza) => acc + calculateArea(pizza.size) * pizza.quantity,
      0
    );
    const totalPriceOption2 = option2Pizzas.reduce(
      (acc, pizza) => acc + pizza.price * pizza.quantity,
      0
    );
  
    const valueOption1 = totalAreaOption1 / totalPriceOption1;
    const valueOption2 = totalAreaOption2 / totalPriceOption2;
  
    let resultMessage = '';
  
    if (totalAreaOption1 > totalAreaOption2) {
      resultMessage += "Conclusion: Option 1 has more pizza and ";
    } else if (totalAreaOption1 < totalAreaOption2) {
      resultMessage += "Conclusion: Option 2 has more pizza and ";
    } else if (totalAreaOption1 === totalAreaOption2) {
      resultMessage += "Conclusion: Both options have the same total area and ";
    }
  
    if (valueOption1 > valueOption2) {
      resultMessage += "Option 1 gives you better value for your money!";
    } else if (valueOption1 < valueOption2) {
      resultMessage += "Option 2 gives you better value for your money!";
    } else if (valueOption1 === valueOption2){
      resultMessage += "Both options provide the same value for your money!";
    } else {
      resultMessage += "Please fill in the fields correctly!";
    }
  
    // Update the state with the calculated values
    setOption1Result({
      totalArea: totalAreaOption1.toFixed(2),
      totalPrice: totalPriceOption1.toFixed(2),
      pricePerSquareInch: (totalPriceOption1 / totalAreaOption1).toFixed(2),
    });
  
    setOption2Result({
      totalArea: totalAreaOption2.toFixed(2),
      totalPrice: totalPriceOption2.toFixed(2),
      pricePerSquareInch: (totalPriceOption2 / totalAreaOption2).toFixed(2),
    });
  
    setConclusionResult(resultMessage);
    setResult(true);
  };

  return (
    <div>
      <h1>Pizza Comparison Calculator</h1>
      <div className="card mb-3">
        <div className="card-body">
          <h2>Option 1</h2>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Diameter (inches)</th>
                  <th>Quantity</th>
                  <th>Price per Pizza</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {option1Pizzas.map((pizza, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="number"
                        name="size"
                        placeholder="Diameter (inches)"
                        value={pizza.size}
                        onChange={(e) => handleInputChange(e, index, 1)}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="quantity"
                        placeholder="Quantity"
                        value={pizza.quantity}
                        onChange={(e) => handleInputChange(e, index, 1)}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="price"
                        placeholder="Price per Pizza"
                        value={pizza.price}
                        onChange={(e) => handleInputChange(e, index, 1)}
                        required
                      />
                    </td>
                    <td>
                      <button onClick={() => removePizza(index, 1)}>Remove</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button onClick={addPizzaOption1}>Add Pizza</button>
        </div>
      </div>
      <div className="card mb-3 mt-50">
        <div className="card-body">
          <h2>Option 2</h2>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Diameter (inches)</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {option2Pizzas.map((pizza, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="number"
                        name="size"
                        placeholder="Diameter (inches)"
                        value={pizza.size}
                        onChange={(e) => handleInputChange(e, index, 2)}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="quantity"
                        placeholder="Quantity"
                        value={pizza.quantity}
                        onChange={(e) => handleInputChange(e, index, 2)}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="price"
                        placeholder="Price"
                        value={pizza.price}
                        onChange={(e) => handleInputChange(e, index, 2)}
                        required
                      />
                    </td>
                    <td>
                      <button onClick={() => removePizza(index, 2)}>Remove</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button onClick={addPizzaOption2}>Add Pizza</button>
        </div>
      </div>
      <button onClick={calculateBestValue} className="calculate">Compare Both Options</button>

      {result && (
        <div className="calculation-results">
          <h3>Calculation Results:</h3>
          <div className="option-section">
            <h4>Option 1</h4>
            <p>{`Total Area = ${option1Result.totalArea} sq inches`}</p>
            <p>{`Total Price = $${option1Result.totalPrice}`}</p>
            <p>{`Price per Square Inch = $${option1Result.pricePerSquareInch}`}</p>
          </div>

          {/* Option 2 Result */}
          <div className="option-section">
            <h4>Option 2</h4>
            <p>{`Total Area = ${option2Result.totalArea} sq inches`}</p>
            <p>{`Total Price = $${option2Result.totalPrice}`}</p>
            <p>{`Price per Square Inch = $${option2Result.pricePerSquareInch}`}</p>
          </div>

          {/* Conclusion */}
          <div className="conclusion-section">
            <h5>Conclusion</h5>
            <p>{conclusionResult}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PizzaAreaCalculator;
